import React, { lazy, Suspense, useContext } from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch, Redirect} from 'react-router-dom';
import { AuthContext } from './context/AuthContext';

const GestionAmbiental = lazy(() => import('./pages/gestionambiental/GestionAmbiental.jsx'));
const Historial = lazy(() => import('./pages/gestionambiental/Historial.jsx'));
const FormAcciones = lazy(() => import('./pages/gestionambiental/CrearAccion.jsx'));
const GestionMonitoreo = lazy(() => import('./pages/gestionMonitoreo/Inicio.jsx'));
const Consultas = lazy(() => import('./pages/gestionMonitoreo/Consultas.jsx'));
const ProgramacionMuestreos = lazy(() => import('./pages/gestionMonitoreo/Programacion.jsx'));
const EjecucionMuestreos = lazy(() => import('./pages/gestionMonitoreo/Ejecucion.jsx'));
const NoEjecucion = lazy(() => import('./pages/gestionMonitoreo/Noejecutadas.jsx'));
const ENoEjecucion = lazy(() => import('./pages/gestionMonitoreo/Noejecutadas2.jsx'));
const Flujodeldato = lazy(() => import('./pages/gestionMonitoreo/Flujodedato.jsx'));
const Home = lazy(() => import('./pages/home.jsx'));
const LoadingFallback = () => <div></div>;

const UnauthenticatedRoutes = () => (
  <Switch>

    <Route path='/' exact>
      <Home />
    </Route>
  </Switch>
);

const AuthenticatedRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route exact
      {...rest}
      render={() =>
        auth.isAuthenticated() ? <div>{children}</div> : <Redirect to='/' />
      }
    />
  );
};

const AdminRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route exact
      {...rest}
      render={() =>
        auth.isAuthenticated() && auth.authState.userInfo.id_rol==253 ? <div>{children}</div> : <Redirect to='/' />
      }
    />
  );
};


const AppRoutes = () => {
const auth = useContext(AuthContext);
// console.log(auth)
return (
  <>
    <Suspense fallback={<LoadingFallback />}>
      <Switch>
        <AuthenticatedRoute path='/Gestionmonitoreo' >
          <GestionMonitoreo />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/Consultas'>
          <Consultas />
        </AuthenticatedRoute>

        <Route 
          path="/Programacionmuestreos"
          exact
          render={() =>
            auth.isAuthenticated() && (auth.authState.userInfo.id_rol==252 || auth.authState.userInfo.id_rol==250) ? (
            <ProgramacionMuestreos />
            ) : (
                <Redirect to='/' />
              )
          }
        />
       
       <Route
          path="/ejecucionmuestreos"
          exact
          render={() =>
            auth.isAuthenticated() && (auth.authState.userInfo.id_rol==252 || auth.authState.userInfo.id_rol==250) ? (
            <EjecucionMuestreos />
            ) : (
                <Redirect to='/' />
              )
          }
        />

        <Route
          path="/noejecutadas"
          exact
          render={() =>
            auth.isAuthenticated() && (auth.authState.userInfo.id_rol==252 || auth.authState.userInfo.id_rol==250) ? (
            <NoEjecucion/>
            ) : (
                <Redirect to='/' />
              )
          }
        />

        <Route
          path="/flujodeldato"
          exact
          render={() =>
            auth.isAuthenticated() && (auth.authState.userInfo.id_rol==252 || auth.authState.userInfo.id_rol==250) ? (
              <Flujodeldato />
            ) : (
                <Redirect to='/' />
              )
          }
        />


        <AuthenticatedRoute path="/GestionAmbiental">
          <GestionAmbiental />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/historial">
          <Historial />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/Enoejecutadas">
          <ENoEjecucion />
        </AuthenticatedRoute>

    
       

        <Route
          path="/formAcciones"
          exact
          render={() =>
            auth.isAuthenticated() && (auth.authState.userInfo.id_rol==254 || auth.authState.userInfo.id_rol==250 || auth.authState.userInfo.id_rol==252 || auth.authState.userInfo.id_rol==253 ) ? (
              <FormAcciones />
            ) : (
                <Redirect to='/' />
              )
          }
        />

        <UnauthenticatedRoutes />
      </Switch>
    </Suspense>
  </>
)};

export default AppRoutes;


