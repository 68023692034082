/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Cookies, { set } from 'js-cookie';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();

  // Cookies Names
  const CookiesNames = {
    userAccess: 'access',
    // userRefresh: "_user_refresh_access",
    // expiresAt: '_expires_at',
    userInfo: '_user_info',
    project: '_selected_project',
  };

  const access = Cookies.get(CookiesNames.userAccess);
  // const refresh = Cookies.get(CookiesNames.userRefresh);
  // const expiresAt = Cookies.get(CookiesNames.expiresAt);
  const userInfo = Cookies.get(CookiesNames.userInfo);
  const project = Cookies.get(CookiesNames.project);

  const [authState, setAuthState] = useState({
    access,
    // refresh,
    // expiresAt,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  });

  const [selPro, setSelPro] = useState(project ? JSON.parse(project) : null);

  function setProject(projectsel) {
    Cookies.set(CookiesNames.project, JSON.stringify(projectsel));
    setSelPro(projectsel);
    // //console.log(projectsel);
  }

  function setAuthInfo({ access, userInfo }) {
    Cookies.set(CookiesNames.userAccess, access);
    // Cookies.set(CookiesNames.userRefresh, refresh);
    // Cookies.set(CookiesNames.expiresAt, expiresAt);
    Cookies.set(CookiesNames.userInfo, JSON.stringify(userInfo));

    setAuthState({
      access,
      // refresh,
      // expiresAt,
      userInfo,
    });
  }

  const logout = () => {
    Cookies.remove(CookiesNames.userAccess);
    // Cookies.remove(CookiesNames.userRefresh);
    // Cookies.remove(CookiesNames.expiresAt);
    Cookies.remove(CookiesNames.userInfo);
    Cookies.remove(CookiesNames.project);
    setAuthState({});
    history.push('/');
  };

  const isAuthenticated = () => {
    if (!authState.access) {
      return false;
    }
    // return new Date().getTime() / 1000 < authState.expiresAt;
    return true;
  };

  const isAdmin = () => {
    let x;
    const info = authState.userInfo;
    const pro = selPro;
    // console.log(info);
    // console.log(pro);
    // console.log('verificando Admin');

    if (pro != null) {
      // console.log('Encontro proyecto');
      // console.log(pro.codigo);
      // console.log(info.roles);
      x = info.roles.find((value) => value.id_proyecto === pro.codigo);
      // console.log(x);
      // console.log();
      return x.descripcion.toUpperCase().search('ADMIN') >= 0;
    }
    return false;
  };

  return (
    <Provider
      value={{
        project,
        setSelPro: (pro) => setProject(pro),
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
        isAdmin,
      }}
    >
      {children}
    </Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
AuthProvider.defaultProps = {
  children: null,
};

export { AuthContext, AuthProvider };
