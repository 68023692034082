import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { AuthProvider } from './context/AuthContext'
import { FetchProvider } from './context/FetchContext'
import AppRoutes from './Routes'
import { ToastProvider } from 'react-toast-notifications'
import { createBrowserHistory } from 'history'

const App = () => {
  const history = createBrowserHistory({
    basename: '/redcam-icam-gestion',
  })
  return (
    <Router>
      {/* // <Router> */}
      <AuthProvider>
        <FetchProvider>
          <ToastProvider>
            <div className='containerApp'>
              <AppRoutes />
            </div>
          </ToastProvider>
        </FetchProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
